import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';
import PostPreview from '../components/postpreview';

class BlogIndexTemplate extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges;
        const langKey = this.props.pageContext.langKey;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang={langKey}
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO
                    lang={langKey}
                    title="Blog"
                    description={data.site.siteMetadata.description}
                />
                <TopHeading>Blog</TopHeading>
                {posts.map(({ node }) => {
                    return <PostPreview post={node} key={node.fields.slug} />;
                })}
            </Layout>
        );
    }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
    query($langKey: String!) {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMarkdownRemark(
            filter: {
                fields: { langKey: { eq: $langKey } }
            }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        langKey
                    }
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`;
